import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// Custom i18next KeyDebugPlugin plugin
// Scheibt den i18n-Key vor jeden Text im HTML
// So lassen sich Texte schnell in der translation.json finden
const KeyDebugPlugin = {
  type: 'postProcessor',
  name: 'keyDebug',
  process: function (value, key, options) {
    return `${key}: ${value}`;
  },
};

i18next
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)

  //.use(KeyDebugPlugin) // <-- Use the KeyDebugPlugin plugin

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'de',
    lng: 'de',
    returnEmptyString: false,
    fallbackOnEmpty: true,
    //postProcess: ['keyDebug'], // <-- Use the KeyDebugPlugin plugin
  });

export default (app) => {
  app.use(I18NextVue, { i18next });
  return app;
};
