<template>
  <footer
    class="my-bg"
    aria-labelledby="footer-heading"
  >
    <h2
      id="footer-heading"
      class="sr-only"
    >
      Footer
    </h2>
    <div class="mx-auto max-w-7xl px-6 pb-4 pt-16 sm:pt-24 lg:px-8 lg:pt-32 border-t-2">
      <div class="border-t border-gray-900/10 pt-4 ">
        <div class="flex items-center justify-between">
          <p class="text-xs leading-5 text-gray-700 order-first">
            &copy; 2023 {{ people.name }}
          </p>
          <div class="flex">
            <router-link
              class="text-sm leading-6 text-gray-600 hover:text-gray-900 ml-4"
              to="/contact"
              @click="scrollToTop"
            >
              Kontakt
            </router-link>
            <router-link
              class="text-sm leading-6 text-gray-600 hover:text-gray-900 ml-4"
              to="/privacypolicy"
              @click="scrollToTop"
            >
              Datenschutzerklärung
            </router-link>
            <router-link
              class="text-sm leading-6 text-gray-600 hover:text-gray-900 ml-4"
              to="/imprint"
              @click="scrollToTop"
            >
              Impressum
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { defineComponent, h } from 'vue'



const scrollToTop = () => {
  document.documentElement.scrollTop = 0;
};

</script>
