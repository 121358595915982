<template>
  <div class="relative isolate rounded-2xl overflow-hidden bg-gray-900 pt-24 sm:py-32">
    <!-- <img
      src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
      alt=""
      class="absolute inset-0 -z-10 h-full w-full object-cover object-right md:object-center"
    > -->

    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:mx-0">
        <h2 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">
          {{ $t('home.HeaderOffer.A_title') }}
        </h2>
        <p class="mt-6 text-lg leading-8 text-gray-300">
          {{ $t('home.HeaderOffer.B_subtitle') }}
        </p>
      </div>

      <div
        class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-4 lg:gap-8"
      >
        <div
          v-for="offer in offers.submenu"
          :key="offer.name"
          class="group relative rounded-xl bg-white/5 p-6 ring-1 ring-inset ring-white/10 text-base leading-6 hover:bg-gray-500/50"
        >
          <div class="flex h-11 w-11 items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
            <component
              :is="getIconForSubMenu(offer.icon)"
              class="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
              aria-hidden="true"
            />
          </div>
          <router-link
            class="mt-6 block font-semibold text-white pb-5"
            :to="offer.href"
            @click="scrollToTop"
          >
            {{ offer.name }}
            <span class="absolute inset-0" />
          </router-link>
          <p
            v-for="desc in offer.description"
            :key="desc"
            class="mt-1 text-gray-300 text-base leading-5 pb-1"
          >
            {{ desc }}
          </p>
          <p class="mt-5 text-gray-500 text-sm ">
            {{ $t('home.HeaderOffer.ReadMore') }}
          </p>
        </div>
        <div
          class="absolute -top-24 right-0 -z-10 transform-gpu blur-3xl"
          aria-hidden="true"
        >
          <div
            class="aspect-[1404/767] w-[87.75rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-[0.15] "
            style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useTranslation } from 'i18next-vue'

import {
  ChatBubbleLeftRightIcon,
  UserGroupIcon,
  FaceFrownIcon,
  ArrowsPointingInIcon,
} from '@heroicons/vue/24/outline'

const scrollToTop = () => {
  document.documentElement.scrollTop = 0;
};

// eslint-disable-next-line vue/require-default-prop
const props = defineProps({
  subMenu: {
    type: String,
    required: true
  }
})
const { t } = useTranslation()
const offers = computed(() => t(props.subMenu, { returnObjects: true }))

function getIconForSubMenu(iconName) {
  switch(iconName) {
    case 'UserGroup':
      return UserGroupIcon
      break
    case 'ArrowsPointingIn':
      return ArrowsPointingInIcon
      break
    case 'FaceFrown':
      return FaceFrownIcon
      break
    case 'ChatBubbleLeftRight':
      return ChatBubbleLeftRightIcon
      break
    default:
      return UserGroupIcon
  }
}
</script>
