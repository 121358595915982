import { createApp } from 'vue'
import App from '@/App.vue'
import i18n from '@/i18n'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { plugin, defaultConfig } from '@formkit/vue'
import router from '@/router/index'
import '@/assets/css/tailwind.css'

let app = createApp(App)
  .use(router)
  .use(autoAnimatePlugin)
  .use(plugin, defaultConfig({ theme: 'genesis' }))

// global properties
app.config.globalProperties.PUBLIC_PATH = process.env.BASE_URL
app.config.globalProperties.people =
{
  name: 'Antonio Gloria',
  strasse: 'Schwanthalerstraße 141',
  stadt: '80339 München',
  telefon: '+49 (0) 151 70665390',
  email: 'Trainer@AntonioGloria.de'
}


i18n(app).mount('#app')
