<template>
  <div class="LanguageSwitcher my-bg">
    <button
      v-for="lng in languages"
      :key="lng"
      class="bg-center cursor-pointer w-7 mr-2 hover:opacity-50"
      @click="$i18next.changeLanguage(lng)"
      @keydown="bar"
    >
      <img
        :src="`${PUBLIC_PATH}img/flags/flag-${lng}.png`"
        :alt="`flag ${lng}`"
      >
    </button>
  </div>
</template>

<script setup>
const languages = ['de', 'en', 'it']
//const languages = ['de', 'en', 'es', 'fr', 'it', 'nl']
</script>
