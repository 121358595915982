<template>
  <section>
    <div class="my-bg py-24 sm:py-32">
      <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <HeaderTxt
              :title="title"
              :subtitle="subtitle"
            />
            <p class="mt-6 text-base leading-7 text-gray-600">
              {{ description }}
            </p>
          </div>
          <dl class="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
            <div
              v-for="feature in featureList.point"
              :key="feature.name"
              class="relative pl-9"
            >
              <dt class="font-semibold text-gray-900">
                <CheckIcon
                  class="absolute top-1 left-0 h-5 w-5 text-indigo-500"
                  aria-hidden="true"
                />
                {{ feature.name }}
              </dt>
              <dd class="mt-2">
                {{ feature.description }}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </section>
</template>


<script setup>
import HeaderTxt from '@/components/HeaderTxt.vue'
import { CheckIcon } from '@heroicons/vue/20/solid'
import { computed } from 'vue'
import { useTranslation } from 'i18next-vue'

const props = defineProps({
  myi18n: {
    type: String,
    required: true
  },
  title: {
    type: String,
    default: null
  },
  subtitle: {
    type: String,
    default: null
  },
  description: {
    type: String,
    default: null
  }
})
const { t } = useTranslation()
const featureList = computed(() => t(props.myi18n, { returnObjects: true }))
</script>
