<template>
  <div v-html="pictureHTML" />
</template>


<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  path: { type: String, default: '/img/' },
  fileName: { type: String, default: '' },
  mysrcset: { type: Array, default: () => [320, 640, 960, 1280, 1920, 2560] },
  alt: { type: String, default: 'Bild' },
  width: { type: [String, Number], required: true },
  height: { type: [String, Number], required: true },
  myclass: { type: String, default: '' },
  mysizes: { type: String, default: '' },
  nolazy: { type: Boolean, default: true } // false Ich habe das ersteinmal für alle abgeschaltet.
});

const computedPath = computed(() => props.path.endsWith('/') ? props.path : `${props.path}/`);
const fileNameWithoutExtension = computed(() => props.fileName.split('.').slice(0, -1).join('.'));
const largestSize = computed(() => props.mysrcset[props.mysrcset.length - 1]);

const generateSrcset = (extension) => {
  return props.mysrcset
    .map(size => `${computedPath.value}${fileNameWithoutExtension.value}-${size}.${extension} ${size}w`)
    .join(',');
};

const pictureHTML = computed(() => {
  const sizes = props.mysizes || `(max-width: ${largestSize.value}px) 100vw, ${largestSize.value}px`;
  const imgClass = props.myclass ? ` class="${props.myclass}"` : '';
  const lazyLoad = props.nolazy ? '' : ' loading="lazy"';

  return `
    <picture>
      <source srcset="${generateSrcset('webp')}" sizes="${sizes}" type="image/webp">
      <source srcset="${generateSrcset('jpg')}" sizes="${sizes}" type="image/jpeg">
      <img${imgClass} src="${computedPath.value}${fileNameWithoutExtension.value}-${props.width}.jpg" width="${props.width}" height="${props.height}" alt="${props.alt}"${lazyLoad}>
    </picture>
  `;
 });


/*
<MyPicture
  path="/meinPfad/"
  fileName="meinBild.jpg"
  mysrcset="[320, 640, 960, 1280, 1920, 2560]"
  alt="Alternativtext"
  width="640"
  height="480"
  myclass="meineKlasse"
  mysizes="(max-width: 640px) 100vw, 640px"
  nolazy="false"
/>
Attribute:

path:     Der Pfad zum Bildordner. Default ist '/img/'.
fileName: Der Dateiname des Bildes mit Erweiterung. Default ist ''.
mysrcset: Ein Array von Bildbreiten, die für das srcset-Attribut verwendet werden. Default ist [320, 640, 960, 1280, 1920, 2560].
alt:      Der Alternativtext für das Bild. Default ist 'Bild'.
width:    Die Breite des Bildes (erforderlich).
height:   Die Höhe des Bildes (erforderlich).
myclass:  Eine optionale Klasse, die dem Bild hinzugefügt wird. Default ist ''.
mysizes:  Eine optionale Größenangabe für das Bild. Default ist ''.
nolazy:   Wenn auf true gesetzt, wird das lazy-Loading des Bildes deaktiviert. Default ist false.

Ergebnis:

Diese Komponente generiert ein HTML-Bild-Element in einem Picture-Element, das sowohl für .webp- als auch .jpg-Bilder optimierte srcset-Attribute enthält, um Responsive Images zu ermöglichen. Sie unterstützt auch Lazy Loading von Bildern und ermöglicht die Anpassung der Bildbreite, -höhe, -alt-Texte und -Klassen.

 */




</script>
