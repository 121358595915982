<template>
  <div class="my-bg">
    <div class="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
      <div class="mx-auto max-w-4xl">
        <h2 class="text-2xl font-bold leading-10 tracking-tight text-gray-900">
          {{ title }}
        </h2>
        <p class="mt-6 max-w-2xl text-base leading-7 text-gray-600">
          {{ description }}
        </p>
        <dl class="mt-10 space-y-6 divide-y divide-gray-900/10">
          <Disclosure
            v-for="faq in MyFaqsContent.faqs"
            :key="faq.A_question"
            v-slot="{ open }"
            as="div"
            class="pt-6"
          >
            <dt>
              <DisclosureButton class="flex w-full items-start justify-between text-left text-gray-900">
                <span class="text-base font-semibold leading-7">{{ faq.A_question }}</span>
                <span class="ml-6 flex h-7 items-center">
                  <PlusSmallIcon
                    v-if="!open"
                    class="h-6 w-6"
                    aria-hidden="true"
                  />
                  <MinusSmallIcon
                    v-else
                    class="h-6 w-6"
                    aria-hidden="true"
                  />
                </span>
              </DisclosureButton>
            </dt>
            <DisclosurePanel
              as="dd"
              class="mt-2 pr-12"
            >
              <p class="text-base leading-7 text-gray-600">
                {{ faq.answer }}
              </p>
            </DisclosurePanel>
          </Disclosure>
        </dl>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/vue/24/outline'
import { computed } from 'vue'
import { useTranslation } from 'i18next-vue'

const props = defineProps({
  myi18n: {
    type: String,
    required: true
  },
  title: {
    type: String,
    default: null
  },
  description: {
    type: String,
    default: null
  }
})
const { t } = useTranslation()
const MyFaqsContent = computed(() => t(props.myi18n, { returnObjects: true }))
//console.log('MyFaqsContent:', MyFaqsContent.value)
</script>
