<template>
  <header class="relative isolate z-10 m999y-bg">
    <nav
      class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
      aria-label="Global"
    >
      <div class="flex lg:flex-1">
        <!--  <router-link
          class="-m-1.5 p-1.5"
          to="/"
        >
          <span class="sr-only">Your Company</span>
          <img
            class="h-8 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt=""
          >
        </router-link>-->
      </div>
      <div class="flex lg:hidden">
        <button
          type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          @click="mobileMenuOpen = true"
        >
          <span class="sr-only">Open main menu</span>
          <Bars3Icon
            class="h-6 w-6"
            aria-hidden="true"
          />
        </button>
      </div>
      <PopoverGroup class="hidden lg:flex lg:gap-x-6">
        <router-link
          class="my-menu"
          to="/"
        >
          {{ $t('system.navigation.home') }}
        </router-link>

        <!-- START: Desktop-Dropdown ####################################  -->
        <Popover>
          <PopoverButton class="flex items-center gap-x-1 my-menu">
            {{ $t('system.navigation.myOffer.label') }}
            <ChevronDownIcon
              class="h-5 w-5 flex-none text-gray-400"
              aria-hidden="true"
            />
          </PopoverButton>

          <transition
            enter-active-class="transition ease-out duration-200"
            enter-from-class="opacity-0 -translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 -translate-y-1"
          >
            <PopoverPanel
              v-slot="{ close }"
              class="absolute inset-x-0 top-0 -z-10 pt-14  "
            >
              <div
                class="mx-auto bg-blue-50 shadow-lg ring-1 ring-gray-900/5 mt-6 grid max-w-7xl grid-cols-4 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8"
              >
                <div
                  v-for="item in subMenuMyOffer"
                  :key="item.name"
                  class="group relative rounded-lg p-6 text-base leading-6 hover:bg-blue-100"
                >
                  <div class="flex h-11 w-11 items-center justify-center rounded-lg bg-blue-100 group-hover:bg-blue-50">
                    <component
                      :is="getIconForSubMenu(item.icon)"
                      class="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                      aria-hidden="true"
                    />
                  </div>
                  <router-link
                    class="mt-6 block font-medium text-gray-900 pb-5"
                    :to="item.href"
                    @click="close()"
                  >
                    {{ item.name }}
                    <span class="absolute inset-0" />
                  </router-link>
                  <p
                    v-for="desc in item.description"
                    :key="desc"
                    class="mt-1 text-gray-600 text-sm pb-1"
                  >
                    {{ desc }}
                  </p>
                </div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>
        <!-- END:   Desktop-Dropdown ####################################  -->

        <router-link
          class="my-menu"
          to="/ueber-mich"
        >
          {{ $t('system.navigation.aboutme') }}
        </router-link>
        <router-link
          class="my-menu"
          to="/coachingraum"
        >
          {{ $t('system.navigation.room') }}
        </router-link>
        <!-- <router-link
          class="my-menu"
          to="/seminare"
        >
          Seminar
        </router-link> -->
        <router-link
          class="my-menu"
          to="/contact"
        >
          {{ $t('system.navigation.contact') }}
        </router-link>
      </PopoverGroup>
      <div class="hidden lg:flex lg:flex-1 lg:justify-end">
        <div class=" bg-gray-100">
          <language-switcher />
        </div>
      </div>
    </nav>

    <!-- START: Mobile ####################################  -->
    <Dialog
      as="div"
      class="lg:hidden"
      :open="mobileMenuOpen"
      @close="mobileMenuOpen = false"
    >
      <div class="fixed inset-0 z-10" />
      <DialogPanel
        focus="true"
        class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
      >
        <!-- START: Mobile-Header ####################################  -->
        <div class="flex items-center justify-between pb-7 border-b">
          <p>&nbsp;</p>
          <!-- <a
            href="#"
            class="-m-1.5 p-1.5"
          >
            <span class="sr-only">Your Company</span>
            <img
              class="h-8 w-auto"
              src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
              alt=""
            >
          </a> -->
          <button
            type="button"
            class="-m-2.5 rounded-md p-2.5 text-gray-700 flex items-center hover:bg-slate-50"
            @click="mobileMenuOpen = false"
          >
            <XMarkIcon
              class="h-8 w-8"
              aria-hidden="true"
            />
            <span class="mr-2">Close</span>
          </button>
        </div>
        <!-- START: ####################################  -->
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <router-link
                class="-mx-3 block rounded-lg py-2 px-3 my-menu-mobile"
                to="/"
                @click="mobileMenuOpen = false"
              >
                {{ $t('system.navigation.home') }}
              </router-link>
              <!-- START: Mobile-Dropdown ####################################  -->
              <Disclosure
                v-slot="{ open }"
                as="div"
                class="-mx-3"
              >
                <DisclosureButton
                  class="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 my-menu-mobile"
                >
                  {{ $t('system.navigation.myOffer.label') }}
                  <ChevronDownIcon
                    :class="[open ? 'rotate-180' : '', 'h-5 w-5 flex-none']"
                    aria-hidden="true"
                  />
                </DisclosureButton>
                <DisclosurePanel class="mt-2 space-y-2 border-l-2">
                  <router-link
                    v-for="item in subMenuMyOffer"
                    :key="item.name"
                    :to="item.href"
                    @click="mobileMenuOpen = false"
                  >
                    <div class="block rounded-lg py-2 pl-8 pr-3 my-menu-mobile">
                      {{ item.name }}
                    </div>
                  </router-link>
                </DisclosurePanel>
              </Disclosure>
              <!-- END:   Mobile-Dropdown ####################################  -->

              <router-link
                class="-mx-3 block rounded-lg py-2 px-3 my-menu-mobile"
                to="/ueber-mich"
                @click="mobileMenuOpen = false"
              >
                {{ $t('system.navigation.aboutme') }}
              </router-link>
              <router-link
                class="-mx-3 block rounded-lg py-2 px-3 my-menu-mobile"
                to="/coachingraum"
                @click="mobileMenuOpen = false"
              >
                {{ $t('system.navigation.room') }}
              </router-link>

              <router-link
                class="-mx-3 block rounded-lg py-2 px-3 my-menu-mobile"
                to="/contact"
                @click="mobileMenuOpen = false"
              >
                {{ $t('system.navigation.contact') }}
              </router-link>
            </div>
            <LanguageSwitcher class="pt-6 bg-white   " />
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>

  <!-- <p> Test Schleife</p>
  <div
    v-for="item in subMenuMyOffer"
    :key="item.name"
  >
    <p>{{ item.name }}</p>
  </div> -->
</template>


<script setup>
import { ref, computed } from 'vue'
import { useTranslation } from 'i18next-vue'
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/vue'
import {
  ChatBubbleLeftRightIcon,
  UserGroupIcon,
  FaceFrownIcon,
  ArrowsPointingInIcon,
  Bars3Icon,
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'

const mobileMenuOpen = ref(false)

const { t } = useTranslation()
const subMenuMyOffer = computed(() => t('system.navigation.myOffer.submenu', { returnObjects: true }))

function getIconForSubMenu(iconName) {
  switch(iconName) {
    case 'UserGroup':
      return UserGroupIcon
      break
    case 'ArrowsPointingIn':
      return ArrowsPointingInIcon
      break
    case 'FaceFrown':
      return FaceFrownIcon
      break
    case 'ChatBubbleLeftRight':
      return ChatBubbleLeftRightIcon
      break
    default:
      return UserGroupIcon
  }
}

</script>

<style lang="scss" scoped>
.router-link-active {
  color: #0051ae;
  //background-color: #670518;
  // text-decoration: underline;
}
</style>

<style>
.my-menu {
@apply text-base font-medium leading-7 text-gray-500 hover:text-gray-900
}
.my-menu-mobile {
@apply text-2xl font-light leading-7 text-gray-500 hover:text-gray-900
}
</style>
