<template>
  <TranslationReady>
    <header-layout>
      <main-menu />
    </header-layout>
    <LoadingView v-if="showLoading" />
    <router-view v-else />
    <footer-layout />
  </TranslationReady>
</template>

<script setup>
import HeaderLayout from './components/layouts/HeaderLayout.vue'
import MainMenu from './components/MainMenu.vue'
import FooterLayout from './components/layouts/FooterLayout.vue'
import LoadingView from '@/components/LoadingView.vue'
import TranslationReady from './components/TranslationReady.vue'

import { ref, onMounted } from 'vue'
import router from './router'


// #########################################################
// Dieser Bereich verwaltet die Anzeige der LoadingView-Komponente während der Navigation zwischen den Routen.
// Er setzt einen Timeout von 1 Sekunde, um die LoadingView anzuzeigen,
// und entfernt sie, sobald die Navigation abgeschlossen ist.
const loading = ref(false)
const showLoading = ref(false)
let timeoutId = null

router.beforeEach(() => {
  // Set the loading state to true
  loading.value = true
  // Set a timeout of 1 second to show the loading view
  timeoutId = setTimeout(() => {
    showLoading.value = true
  }, 1000)
})

router.afterEach(() => {
  // Set the loading state to false
  loading.value = false
  // If a timeout was set, clear it
  if (timeoutId) {
    clearTimeout(timeoutId)
  }
  // Hide the loading view
  showLoading.value = false
})

// #########################################################
// Diese Funktionen entfernen den Spinner-Bereich aus der Index.html,
// sobald die Vue-Instanz eingebunden ist.

// mounted Lifecycle Hook mit <script setup> Syntax
onMounted(() => {
  removeSpinner();
});

// Entfernen des Spinners
function removeSpinner() {
  const loadingElement = document.querySelector(".loading");
  if (loadingElement) {
    loadingElement.remove();
  }
}
</script>
