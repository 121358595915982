<template>
  <!--
    Zeigt den Inhalt des Slots nur an, wenn die Übersetzungen geladen wurden.
    Die Komponente verwendet den benutzerdefinierten Hook useTranslationsReady
    um den Ladestatus der Übersetzungen zu verfolgen.
  -->
  <div v-if="isTranslationReady">
    <slot />
  </div>
</template>

<script>
import { ref } from 'vue'
import i18next from 'i18next'

// Erstellt eine ref, um den Ladestatus der Übersetzungen zu verfolgen
const isTranslationReady = ref(false)

// Setzt isTranslationReady auf true, wenn die Übersetzungen initialisiert wurden
i18next.on('initialized', () => {
  isTranslationReady.value = true
})

// Setzt isTranslationReady auf true, wenn die aktive Sprache geändert wurde
i18next.on('languageChanged', () => {
  isTranslationReady.value = true
})

// Benutzerdefinierter Hook, der isTranslationReady bereitstellt
function useTranslationsReady() {
  return { isTranslationReady }
}

export default {
  // Verwendet den benutzerdefinierten Hook useTranslationsReady, um den Wert von
  // isTranslationReady bereitzustellen und die Anzeige des Slot-Inhalts
  // auf der Grundlage des Ladestatus der Übersetzungen zu steuern
  setup() {
    const { isTranslationReady } = useTranslationsReady()
    return { isTranslationReady }
  },
}
</script>




